
































































import { computed, defineComponent } from "@vue/composition-api";

import printJS from "print-js";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(_, { root }) {
    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/location/`
    );
    const print = () => {
      printJS({
        type: "html",
        printable: "print",
        ignoreElements: ["no-print"],
        targetStyles: ["*"],
      });
    };
    return { filesUrl, print };
  },
});
